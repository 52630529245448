<template>
  <div class="container">
    <div class="row">
        <div class="col-12 col-lg-6 c g">
            <div class="card">
                <div class="card-header">
                    <h5>
                        <i class="fa fa-user-plus"></i>
                        اضافة حاج
                    </h5>
                </div>
                <div class="card-body">
                    <div class="row g">
                        <div class="col-6">
                            <button class="btn btn-success btn-block" v-if="create.hamla == '1'">
                                <i class="fa fa-check"></i>
                                البشائر
                            </button>
                            <button class="btn btn-outline-secondary btn-block" @click="create.hamla = '1'" v-if="create.hamla != '1'">
                                البشائر
                            </button>
                        </div>
                        <div class="col-6">
                            <button class="btn btn-success btn-block" v-if="create.hamla == '2'">
                                <i class="fa fa-check"></i>
                                الميسر
                            </button>
                            <button class="btn btn-outline-secondary btn-block" @click="create.hamla = '2'" v-if="create.hamla != '2'">
                                الميسر
                            </button>
                        </div>
                    </div>
                    <template v-if="create.hamla">
                        <div class="form-group">
                            <label for="">الاسم</label>
                            <input type="text"
                                class="form-control" v-model="create.name">
                        </div>
                        <div class="form-group">
                            <label for="">رقم الهوية</label>
                            <input type="text"
                                class="form-control" v-model="create.number">
                        </div>
                        <div class="form-group">
                            <label for="">كلمة المرور</label>
                            <input type="text"
                                class="form-control" v-model="create.password">
                        </div>
                        <div class="form-group">
                            <label for="">الجوال</label>
                            <input type="text"
                                class="form-control" v-model="create.phone">
                        </div>
                        <div class="form-group">
                            <label for="">الحالة</label>
                            <select class="form-control" v-model="create.status">
                                <option v-for="xstatus in statuses" :value="xstatus" :key="xstatus">{{ xstatus }}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="">رقم الحجز</label>
                            <input type="text"
                                class="form-control" v-model="create.order_id">
                        </div>
                        <div class="form-group">
                            <label for="">تاريخ الحجز</label>
                            <input type="text"
                                class="form-control" v-model="create.order_date">
                        </div>
                        <div class="form-group">
                            <label for="">تاريخ الميلاد</label>
                            <input type="text"
                                class="form-control" v-model="create.birthdate">
                        </div>
                        <div class="form-group">
                            <label for="">الجنس</label>
                            <select v-model="create.gender" class="form-control">
                                <option value="انثى">انثى</option>
                                <option value="ذكر">ذكر</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="">الجنسية</label>
                            <input type="text"
                                class="form-control" v-model="create.national">
                        </div>
                        <div class="form-group">
                            <label for="">المخيم</label>
                            <input type="text"
                                class="form-control" v-model="create.camp">
                        </div>
                        <div class="form-group">
                            <label for="">الخطة</label>
                            <input type="text"
                                class="form-control" v-model="create.package">
                        </div>
                        <div class="form-group">
                            <label for="">الشريحة</label>
                            <input type="text"
                                class="form-control" v-model="create.slide">
                        </div>
                        <div class="form-group">
                            <label for="">المدينة</label>
                            <input type="text"
                                class="form-control" v-model="create.city">
                        </div>
                        <div class="form-group">
                            <label for="">النقل</label>
                            <input type="text"
                                class="form-control" v-model="create.transport">
                        </div>
                        <div class="form-group" v-if="manual_location">
                            <label for="">نقطة الانطلاق <i class="fa fa-edit" @click="manual_location = !manual_location"></i></label>
                            <input type="text"
                                class="form-control" v-model="create.start_location">
                        </div>
                        <div class="form-group"  v-if="!manual_location">
                        <label for="">نقطة الانطلاق <i class="fa fa-edit" @click="manual_location = !manual_location"></i></label>
                        <select class="form-control" v-model="create.start_location">
                            <option v-for="c in cities" :key="c" :value="c">
                                {{ c }}
                            </option>
                        </select>
                        </div>
                        <div class="form-group">
                            <label for="">ملاحظات</label>
                            <input type="text"
                                class="form-control" v-model="create.notes">
                        </div>
                        <div class="form-group">
                        <label for="">الحافلة</label>
                        <select class="form-control" v-model="create.bus_id">
                            <template v-for="bus in busses">
                                <option :key="bus._id" :value="bus._id" v-if="bus.hamla == create.hamla">
                                    {{ bus.title }}
                                </option>
                            </template>
                        </select>
                        </div>
                        <div class="form-group">
                        <label for="">صالة منى</label>
                        <select class="form-control" v-model="create.camp_id">
                            <template v-for="camp in camps">
                                <option v-if="camp.type == '1' && camp.hamla == create.hamla" :key="camp._id" :value="camp._id">
                                    {{ camp.title }}
                                </option>
                            </template>
                        </select>
                        </div>
                        <div class="form-group">
                        <label for="">صالة عرفات</label>
                        <select class="form-control" v-model="create.camp2_id">
                            <template v-for="camp in camps">
                                <option v-if="camp.type == '2' && camp.hamla == create.hamla" :key="camp._id" :value="camp._id">
                                    {{ camp.title }}
                                </option>
                            </template>
                        </select>
                        </div>
                        <div class="col-12 text-center g">
                            <button class="btn btn-primary" @click="save()">
                                اضافة الحاج
                            </button>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            create: {
                hamla: false
            },
            user: JSON.parse(localStorage.getItem("user")),
            q: "",
            loading: false,
            hamla: "all",
            manual_location: false,
            page: 0,
            status: "all",
            statuses: [],
            bus_id: "all",
            start_location: "all",
            cities: [],
            statuses: [],
            gender: "all",
            camp_id: "all",
            camp2_id: "all",
        }
    },
    created(){
        var g = this;
        if(!checkPer('users')){
            this.$router.push('/pers')
            return;
        }
        $.post(api + '/admin/busses/list', {
            jwt: g.user.jwt
        }).then(function(r){
            g.busses = r.response;
        })
        $.post(api + '/admin/camps/list', {
            jwt: g.user.jwt
        }).then(function(r){
            g.camps = r.response;
        })
        $.post(api + '/admin/users/list', {
            jwt: this.user.jwt,
            q: this.q,
            hamla: this.hamla,
            page: this.page,
            status: this.status,
            start_location: this.start_location,
            bus_id: this.bus_id
        }).then(function(a){
            g.users = a.response;
            g.users.forEach(function(b){
                if(!g.statuses.includes(b.status)){
                    g.statuses.push(b.status);
                }
                if(!g.cities.includes(b.start_location)){
                    g.cities.push(b.start_location)
                }
            })
        })
    },
    methods: {
        save(){
            var g = this;
            $.post(api + '/admin/users/add', {
                jwt: g.user.jwt,
                user: g.create
            }).then(function(a){
                alert("تم الاضافة بنجاح", 100);
            })
        },
    }
}
</script>

<style>

</style>